.navbar {
  position: absolute;
  top: 0;
  width: 100%;
}

.spartan-logo {
  width: 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.nav-link {
  color: #fff;
  font-weight: bolder;
  font-size: 1.3rem;
}

.nav-link:hover {
  color: #fff;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.active {
  color: #fff !important;
  text-shadow: 1px 2px 2px pink;
}

/**  MEDIA QUERIES **/
@media (max-width: 992px) {
  .navbar {
    position: static;
    background-color: var(--mainColor);
  }
}
