.aboutus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: black;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.4598214285714286) 0%,
      rgba(0, 0, 0, 0.4458158263305322) 100%
    ),
    url("../../../public/assets/img/about.jpg");
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
}

.aboutroute-title {
  font-family: var(--titleFond);
  color: #fff;
  font-size: 5rem;
  text-shadow: 1px 2px 2px pink;
  margin-top: 5rem;
  animation: fadeAnimation 1.5s ease-in forwards;
}

.aboutus-description_container {
  margin-top: 3rem;
  margin-bottom: 4rem;
  width: 90%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  animation: upFadeAnimation 2s ease-in forwards;
}

.aboutus-description_section--top {
  display: flex;
}

.aboutus-description_left {
  width: 30%;
}
.aboutus-description_right {
  padding: 2rem;
  width: 70%;
}
.img-aboutus {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutus-description_text--span {
  margin: 4rem 0;
  font-family: var(--subtitleFond);
  font-size: 2.5rem;
  font-weight: bolder;
  color: var(--mainColor);
}

.aboutus-description_hr {
  width: 70%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--mainColor);
}

.aboutus-description_text--p,
.aboutus-description_text--p2 {
  font-family: var(--subtitleFond);
  font-size: 1.5rem;
  font-weight: bolder;
}

.description-section_bottom--img {
  margin: 3rem 0;
  width: 100%;
  height: 30rem;
  object-fit: cover;
}
.aboutus-description_text--p2 {
  padding: 0 6rem;
  margin: 4rem 0;
}

/* -----  MEDIA QUERIES  ----- */

@media (max-width: 700px) {
  .aboutroute-title {
    font-size: 3.5rem;
  }
  .aboutus-description_text--p,
  .aboutus-description_text--p2 {
    font-size: 1.3rem;
  }

  .aboutus-description_text--p2 {
    padding: 0 3rem;
  }
  .aboutus-description_text--span {
    font-size: 2rem;
  }
  .aboutus-description_right {
    width: 100%;
  }
  .aboutus-description_left {
    display: none;
  }

  .description-section_bottom--img {
    height: 20rem;
  }
}
@media (max-width: 500px) {
  .aboutroute-title {
    font-size: 3rem;
  }
  .aboutus-description_text--span {
    font-size: 1.6rem;
  }
}
