.locationroute-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.4598214285714286) 0%,
      rgba(0, 0, 0, 0.4458158263305322) 100%
    ),
    url("../../../public/assets/img/location.jpg");
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
}

.location-title {
  margin-bottom: 4rem;
  font-family: var(--titleFond);
  color: #fff;
  font-size: 5rem;
  text-shadow: 1px 2px 2px pink;
  margin-top: 6rem;
  animation: fadeAnimation 1.5s ease-in forwards;
}

.locationroute-body {
  margin-bottom: 5rem;
  width: 90%;
  background-color: #fff;
  animation: upFadeAnimation 2s ease-in forwards;
}

.iframe-map {
  margin-bottom: 3rem;
  width: 80%;
  height: 20rem;
  border: 3px solid var(--mainColor);
}

.locationroute-body_title {
  font-family: var(--subtitleFond);
  font-weight: bolder;
  font-size: 2rem;
  margin: 1rem 0;
}
.locationroute-localname {
  font-family: var(--titleFond);
  color: var(--mainColor);
}
.locationroute-body_title--p {
  font-family: var(--subtitleFond);
}
.locationroute-body_title--p:last-child {
  margin-bottom: 2rem;
}
